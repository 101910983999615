import { state, Ctrl, Cmp } from ":mods";
import { A } from "@solidjs/router";
import { login, sendConfirmationEmail } from "../apis";
import { LoginViewProps } from "../models";
import { Match, Show, Switch } from "solid-js";
import { createLoginInputs } from "../forms";
import { ROUTES } from "../const";

export function Login({ $, onLoggedIn, ...props }: LoginViewProps) {
  const app_flavor = Ctrl.Context.hooks.useAppFlavor();
  const app_meta = Ctrl.Context.hooks.useMeta();
  app_meta.setTitle("Login");
  //
  const FormLogin = createLoginInputs("eslam_temp_student");
  const $reset_pass = state.create(false);
  const $totp = state.create(false);

  const onSubmit = async (e: Event) => {
    const values = FormLogin.Actions.getValuesLowercase();
    
    // console.log("login submit");
    if ($reset_pass.value) {
       await sendConfirmationEmail(values.email)
       .then((res)=>{
        Cmp.Alert.actions.pushToast({
          type: "success",
          message: "confirmation email has been sent!",
        });
        console.log("Res:: ", res)
       })
       .catch(({response})=>{
        Cmp.Alert.actions.pushToast({
          type: "error",
          message: "Email Not Found",
        });
       })
      return;


    }
    await login(values)
      .then(() => {
        // $.actions.pushToast({ type: "success", message: "Logged in" });
        onLoggedIn();
        // if (code) {
        // 	log.dev.info("code", code);
        // 	$totp.set(true);
        // } else {
        // }
      })
      .catch(({response}) => {
        $.actions.pushToast({ type: "error", message: response.data.detail });
      });
    return;
  };

  return (
    <section class="flex flex-col !font-rubik$ w-full h-full items-center justify-center">
      <form class="flex flex-col !font-rubik$ w-493px h-fit bg#pt text#p rounded-lg p-40px ">
        <h1 class={`flex$ extend$w trim$ fit$h fight$ font-700 font-bold  capitalize text-31px mb-20px`}>
          <Switch fallback={"Login to Museums Future" || app_flavor.id}>
            <Match when={$reset_pass.value}>Email Verification</Match>
            <Match when={$totp.value}>TOTP Auth</Match>
          </Switch>
        </h1>
        <Switch fallback={null}>
          <Match when={!$totp.value} keyed={true}>
            <FormLogin.Email.Label title="" class="form-label relative flex$row pull$ min-h-15px">
              <FormLogin.Email.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
            </FormLogin.Email.Label>
            <FormLogin.Email.Input
              autocomplete="username"
              type="email"
              class="form-input text-0.8rem px-2.5 py-2 mb-2"
              placeholder="Email"
            />
          <Show when={!$reset_pass.value}>
              <FormLogin.Password.Label title="" class="form-label relative flex$row pull$ min-h-15px">
                  <FormLogin.Password.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
                </FormLogin.Password.Label>
                <FormLogin.Password.Input
                  autocomplete="current-password"
                  type="password"
                  class="form-input"
                  placeholder="Password"
                />
          </Show>
          </Match>

            {/* <Match when={$totp.value}>

          </Match> */}
        </Switch>

        <FormLogin.Actions.Button
          class="form-btn flex$  min-h-2rem text-0.8rem px-2 py-1 fight$ my-24px"
          onclick={onSubmit}
          statusValid={() => ($totp.value ? "Confirm TOTP" : !$reset_pass.value ? `Login` : "Send Email")}
          statusInvalid={!$reset_pass.value ? `Login` : "Send Email"}
          // onlyStatus={["valid", "submit"]}
        />
        <div class="text-xs !mt24px text-center cursor-pointer">
          <span
            class=" text-16px p-0 px-2 underline text#p"
            onClick={(e) => {
              if (!$reset_pass.value) {
              }
              FormLogin.Password.control.markDisabled(!$reset_pass.value);
              $reset_pass.set(!$reset_pass.value);
            }}
          >
            {!$reset_pass.value ? "Reset Password" : "Back To Login"}
          </span>
        </div>
        <div class="text-xs !mt24x text-center cursor-pointer">
          Don't have an account?
          <A class=" text-16px p-0 mx-1 underline text#p" href={`..${ROUTES.auth.signup}`}>
            Create one
          </A>
        </div>
      </form>
    </section>
  );
}

// case: disable button when inputs are disables